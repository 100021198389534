import { useState } from 'react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';

const ValidZipCode = ({ onOpen , onClose, onValidate, errorMessage }) => {
  const [zipCode, setZipCode] = useState('');
  const [errorInterno, setErrorInterno] = useState('');

  const handleValidate = () => {
    if (zipCode.length === 5) {
      setErrorInterno('');
      onValidate(zipCode);
    } else {
      setErrorInterno('Por favor ingresa un código postal válido de 5 dígitos.');
    }
  };

  return (
    <Dialog
      visible={onOpen}
      modal
      onHide={onClose}
    >
      <div className="flex flex-column px-8 py-5 gap-4" style={{ borderRadius: '12px', backgroundImage: 'radial-gradient(circle at left top, #a0c4ff, #3f72af)' }}>
        <div className="inline-flex flex-column gap-2">
          {!errorMessage && <h1 className="text-center">Hola..!!</h1>}
          {errorMessage && <h1 className="text-red-500 text-center">Opss...!!</h1>}
          <h3>Antes de continuar necesitamos saber en donde se entregará</h3>
          <h5 className="text-center">Entregamos en CDMX (Área metropolitana).<br />Ingresa tu Código Postal para confirmar si llegamos a tu zona.</h5>
          {errorMessage && <h5 className="text-red-500 text-center">Una dispcula al parecer no llegamos a donde estas actualmente</h5>}
          {errorInterno && <h5 className="text-red-500 text-center">{errorInterno}</h5>}
          <label htmlFor="cp" className="text-primary-50 font-semibold">Código postal</label>
          <InputText
            id="cp"
            value={zipCode}
            onChange={(e) => setZipCode(e.target.value)}
            className="bg-white-alpha-20 border-none p-3 text-primary-50"
            keyfilter="num"
            maxLength={5}
          />
        </div>
        <div className="flex justify-content-end">
          <Button label="Validar" onClick={handleValidate} text className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10" />
          <Button label="Cancelar" onClick={onClose} text className="p-3 w-full text-primary-50 border-1 border-white-alpha-30 hover:bg-white-alpha-10" />
        </div>
      </div>
    </Dialog>

  );
};

export default ValidZipCode;
