import ProductsView from './components/ProductsView';
import ValidZipCode from './components/ValidZipCode';

const ShopContainer = () => {
  return (
    <>
      <ProductsView />
      <ValidZipCode />
    </>
  );

};

export default ShopContainer;
