import { TYPE_PAGE_STATE } from "../../types/PageState/PageStateTypes";

export const setSectionPage = (sectionPage) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SCROLL_VIEW, payload: sectionPage });
}
export const setProductDetail = (productDetail) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.PRODUCT_DETAIL, payload: productDetail });
}
export const setFilters = (filters) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_FILTERS, payload: filters });
}
export const setFiltersSelect = (filtersSelect) => async (dispatch) => {
    //console.log('Filters',filtersSelect);
    dispatch({ type: TYPE_PAGE_STATE.SET_AUXfILTERS, payload: filtersSelect });
}
export const setPriceItem = (price) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_PRICE_ITEM, payload: price });
}
export const setPriceKg = (price) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_PRICE_KG, payload: price });
}
export const setSampleKg = (sample) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_SAMPLE_KG, payload:sample  });
}
export const setCategory = (sample) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_CATEGORY_ITEM, payload:sample  });
}
export const setCategorySelect = (sample) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_CATEGORY_SELECT_ITEM, payload:sample  });
}
export const setSubcategorySelect = (sample) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_SUBCATEGORY_SELECT_ITEM, payload:sample  });
}
export const setSubcategory = (sample) => async (dispatch) => {
    dispatch({ type: TYPE_PAGE_STATE.SET_SUBCATEGORY_ITEM, payload:sample  });
}