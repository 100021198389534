import { TYPE_LOGIN } from '../../types/auth/authTypes';

const initialState = {
  isAuthenticated: false,
  user: null,
  form: {
    email: '',
    password: '',
  },
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case TYPE_LOGIN.LOGIN_SET_VALUES: {
      return {
        ...state,
        form: {
          ...state.form,
          [action.payload.name]: action.payload.value,
        },
      };
    }
    case TYPE_LOGIN.LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      };
    case TYPE_LOGIN.LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

export default authReducer;
