import { TYPE_PRODUCT_VIEWER } from '../../types/productViewer/productViewerTypes';

export const setLayout = (layout) => async (dispatch) => {
  dispatch({ type: TYPE_PRODUCT_VIEWER.VIEWER_SET_LAYOUT, payload: layout });
};

export const setProducts = (products) => async (dispatch) => {
  dispatch({ type: TYPE_PRODUCT_VIEWER.VIEWER_SET_PRODUCTS, payload: products });
}
export const setProductsPromoted = (products) => async (dispatch) => {
  dispatch({ type: TYPE_PRODUCT_VIEWER.VIEWER_SET_PRODUCTS_PROMO, payload: products });
}
