import { Button } from "primereact/button"
import { ButtonGroup } from "primereact/buttongroup"
import '../css/productView.css'
import { useState } from "react"
import { addElementToCart } from "../../../../redux/actions/shop/shopActions"
import { useDispatch } from "react-redux"
import { Chip } from 'primereact/chip'
import useProductsViewHook from "../../shopContainer/hooks/useProductsViewHook"

const ProductDescription = ({ detail }) => {
    const [QuantityProduct, setQuantityProduct] = useState(1);
    const dispatch = useDispatch()
    const productHook = useProductsViewHook();
    return (
        <div className="flex flex-column justify-content-between h-full py-3">
            <div className="card-container">
                <p className="title-desc">{detail.productName.toUpperCase()}</p>
                <p className="detail-desc">{detail.subCategoryName}</p>
            </div>
            <div className="">
                {
                    detail.features?.map((item, index) => {
                        return (
                            index <= 6 && <Chip style={{marginLeft:'1rem', marginBottom:'1rem'}} className="chip-desc" label={item.featureDesc + " : " + item.featureValue} icon="pi pi-circle-fill" />
                        )
                    })
                }
            </div>
            <div className="flex flex-column gap-5">
                <p className="price-desc">$ {detail.price}</p>
                <div className="flex flex-row justify-content-between gap-6">
                    <ButtonGroup className='flex-1'>
                        <Button className="w-4 button-desc" disabled={QuantityProduct < 2} icon="pi pi-minus" onClick={() => { setQuantityProduct(QuantityProduct - 1) }} />
                        <Button className="w-4 button-desc" disabled label={QuantityProduct.toString()} />
                        <Button className="w-4 button-desc" icon="pi pi-plus" onClick={() => setQuantityProduct(QuantityProduct + 1)} />
                    </ButtonGroup>
                    <Button className="flex-1 button-desc" label="PIEZAS"></Button>
                    {/* <Button className="flex-1 button-desc" label="GRAMOS"></Button> */}
                </div>
                <Button icon='pi pi-shopping-cart' className="button-desc" label="AGREGAR AL CARRITO" onClick={() => { productHook.functions.addToCart(detail,QuantityProduct) }}> </Button>
                <p className="favoritos-desc"><i className="pi pi-heart" style={{ fontSize: 'inherit', marginRight: '1rem' }}></i>Añadir a mis productos favoritos</p>
            </div>
        </div>
    )
}
export default ProductDescription