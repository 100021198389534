import React, { useEffect, useRef, useState } from "react";
import { useLazyFetch } from "../../../hooks/common/useFetchV2"
import GalleryAllProductos from "./components/galleryAllProducts"
import { Toast } from "primereact/toast";
import FilterProducts from "./components/filterProducts";
import { useDispatch, useSelector } from "react-redux";
import { setCategory, setFilters, setSubcategory, setSubcategorySelect } from "../../../redux/actions/pageState/pageStateActions";

const AllProductsView = () => {
    const { getDataFetch } = useLazyFetch();
    const [products, setProducts] = useState([]);
    const [productsAll, setProductsAll] = useState([]);
    const toast = useRef();
    const statePage = useSelector((state) => state.pageStateReducer);
    const dispatch = useDispatch();
    const { filtersSelect, rangePrice, rangeGR, rangePriceKG, category, categorySelect, subcategorySelect } = statePage;
    useEffect(() => {
        getAllProductFeatures();
        getProducts();
        getCategory();
        
    }, [])
    useEffect(() => {
        let filterCategory = []
        if (categorySelect.length > 0) {
            for (let obj of categorySelect) {
                let auxProducts = productsAll.filter((item) => item.category === obj.featureValue);
                filterCategory.push(...auxProducts);
            }
        }else{
            filterCategory=[...productsAll];
        }
        let filterSubCategory=[]
        if (subcategorySelect.length > 0) {
            for (let obj of subcategorySelect) {
                let auxProducts = filterCategory.filter((item) => item.subCategoryName === obj.featureValue);
                filterSubCategory.push(...auxProducts);
            }
        }
        else{
            filterSubCategory=[...filterCategory];
        }
        let filterPresentacion=[]
        if (filtersSelect.length > 0) {
            for (let obj of filtersSelect) {
                let auxProducts = filterSubCategory.filter((item) => item.features?.find((cat => cat.featureValue === obj.featureValue)));
                //console.log("aux", auxProducts);
                filterPresentacion.push(...auxProducts);
            }
        }else{
            filterPresentacion=[...filterSubCategory]
        }
        

        filterPresentacion = filterPresentacion.filter((item) => (item.price > rangePrice[0] && item.price < rangePrice[1]));
        //finalFilterProduct = finalFilterProduct.filter((item)=>(item.features?.find((cat => cat.featureValue === 'Precio por Kilogramo')))?.featureValue === );
        setProducts(filterPresentacion);


    }, [filtersSelect, rangePrice, categorySelect, subcategorySelect]);

    // useEffect(() => {
    //     if (filtersSelect.length > 0) {
    //         let finalFilterProduct = []
    //         setProducts([...finalFilterProduct]);
    //     } else {
    //         setProducts(productsAll);
    //     }
    // }, [])

    const getProducts = async () => {
        let data = await getDataFetch('/public/products', 'GET', {});
        if (data.errorFetch) {
            toast.current.show({
                detail: 'No se logró registrar el correo, intentelo más tarde',
                summary: 'Error',
                severity: 'error'
            })
        } else {
            setProducts(data.data);
            setProductsAll(data.data);
            getCategoryProducts(data.data);
        }
    }
    const getAllProductFeatures = async () => {
        let data = await getDataFetch('/public/v1/products/getAllProductFeatures', 'GET', {});
        if (data.errorFetch) {
            toast.current.show({
                detail: 'No se logró la consulta de datos',
                summary: 'Error',
                severity: 'error'
            })
        } else {
            //console.log(data.data);
            let features = data.data;
            let auxFilter = []
            let category = features.filter((item) => item.featureDesc === 'Presentación');
            auxFilter.push({
                name: 'Presentación',
                subcategory: category.filter((objeto, indice, self) => indice === self.findIndex((o) => o.featureValue === objeto.featureValue))
            });
            dispatch(setFilters(auxFilter));
        }
    }

    const getCategoryProducts = (data) => {
        let categoryAux = data.filter((objeto, indice, self) => indice === self.findIndex((o) => o.category === objeto.category));
        let subcategoryAux = data.filter((objeto, indice, self) => indice === self.findIndex((o) => o.subCategoryName === objeto.subCategoryName))
        let CategoryFinal = [];
        let subCategoryFinal = [];
        for (let item of categoryAux) {
            CategoryFinal.push({
                featureValue: item.category
            })
        }
        for (let item of subcategoryAux) {
            subCategoryFinal.push({
                featureValue: item.subCategoryName
            })
        }
        categoryAux = [
            {
                name: 'Categoría',
                subcategory: CategoryFinal,
            }
        ]
        subcategoryAux = [
           { 
            name: 'Subcategoría',
            subcategory:subCategoryFinal,
        }
        ]

        dispatch(setCategory(categoryAux));
        //console.log('SUB', subcategoryAux);
        dispatch(setSubcategory(subcategoryAux));

    }

    const getCategory = () => {
        let uniqueProfucts = productsAll.filter((objeto, indice, self) => indice === self.findIndex((o) => o.category === objeto.category));
        dispatch(setCategory(uniqueProfucts));
    }

    return (
        <div className="flex flex-grow-1 pt-5 justify-content-center container-products-view">
            <Toast ref={toast}></Toast>
            <div className="flex w-screen md:w-8">
                <div className="grid w-full">
                    <div className="col-3 hidden md:flex h-full">
                        <div className="w-full h-full filter-container">
                            <FilterProducts records={products?.length} ></FilterProducts>
                        </div>

                    </div>
                    <div className="col-12 md:col-9 h-full">
                        <GalleryAllProductos Products={products}></GalleryAllProductos>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default AllProductsView;