import React from 'react';
import { Button } from 'primereact/button';
import '../css/SectionFive.css';
const SectionFive = ({reference}) => {
    return (
        <>

        <div className='SectionFive' ref={reference}>
            <div className="grid mt-7 md:mx-8 lg:mx-8">
                <div className="col-12 md:col-6 lg:col-6 flex justify-content-center">
                    <img src="https://dizstore.shop/media/main_couple.jfif" alt="Cocineros" className="w-9 shadow-2 surface-border border-round" />
                </div>
                <div className="col-12 md:col-6 lg:col-6">
                    <h1 className='title05 text-center lg:text-left'>NUESTRA COMUNIDAD</h1>
                    <h1 className='text05 text-center lg:text-left'>La Fuderia es el combo completo. Queremos consentirte más de dos veces por quincena.
</h1>
                    <h1 className='text05 text-center lg:text-left'>Y si quieres quedar bien con el match o tus amistades, te compartimos recetas usando lo mejor de nuestra cocina para que fácil y rápido comas como tú te mereces.</h1>
                    <div className="grid col-12 justify-content-center">
                        <Button className="ver-recetas-btn" label="VER RECETAS" />
                    </div>
                </div>
            </div>
            </div>
        </>
    );
}

export default SectionFive;