import CartProductsViewer from "./components/CartProductsViewer";
import PriceBoxContainer from "./components/PriceBoxContainer";
import useCartProductsViewerHook from "./hooks/useCartProductsViewerHook";

const ShoppingCart = () => {
    const { states, } = useCartProductsViewerHook();
    const { purchaseCart } = states;

    return (
        <>
            {purchaseCart && purchaseCart.length === 0 ?
                <h1>No hay productos en el carrito</h1> :

                <div className="grid">
                    <div className="col-12 md:col-8" >
                        <div className="card">
                            <p className="text-3xl w-10 font-bold">Carrito de compra</p>
                            <CartProductsViewer />
                        </div>
                    </div>

                    <div className="col-12 md:col-4" >
                        <div className="card">
                            <p className="text-xl w-10 font-bold">Resumen de compra</p>
                            <PriceBoxContainer />
                        </div>
                    </div>

                </div>

            }
        </>
    );
};

export default ShoppingCart;
