import React, { useState, useRef, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputIcon } from "primereact/inputicon";
import { IconField } from "primereact/iconfield";
import { Button } from 'primereact/button';
import { InputMask } from "primereact/inputmask";
import useFormPayHook from "../hooks/useFormPayHook";
import { Controller, useForm } from 'react-hook-form';
import { Message } from 'primereact/message';
import { ToggleButton } from 'primereact/togglebutton';
import { Steps } from 'primereact/steps';
import { Toast } from 'primereact/toast';
import { InputOtp } from 'primereact/inputotp';
import { SelectButton } from 'primereact/selectbutton';
import { Dropdown } from 'primereact/dropdown';
import iconVisa from '../../../../assets/images/icons8-visa.svg'
import iconMaster from '../../../../assets/images/icons8-mastercard.svg'
import iconAmex from '../../../../assets/images/icons8-american-express-al-cuadrado.svg'
import Cookies from "universal-cookie";
import ValidZipCode from "../../shopContainer/components/ValidZipCode";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2";
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";
import { useDispatch } from "react-redux";
import { clearCart } from "../../../../redux/actions/shop/shopActions";
import { Panel } from "primereact/panel"
import { Divider } from "primereact/divider"
import visa from '../../../../assets/images/cards/294654_visa_icon.svg';
import masterCard from '../../../../assets/images/cards/masterCard.svg';
import carnet from '../../../../assets/images/cards/Carnet.png';
import amex from '../../../../assets/images/cards/amex.svg';
import bbva from '../../../../assets/images/cards/bbva_logo_icon_169509.svg';
import santander from '../../../../assets/images/cards/Santander-Logo.png';
import hsbc from '../../../../assets/images/cards/HSBC_icon-icons.com_60512.svg';
import scotia from '../../../../assets/images/cards/scotiabank-logotipo.svg';
import inbursa from '../../../../assets/images/cards/logo_inbursa_acceso.png';
import ixe from '../../../../assets/images/cards/ixe.svg';
import cvvb from '../../../../assets/images/cards/cvvb.png';
import cvvf from '../../../../assets/images/cards/cvvf.png';
import openpayI from '../../../../assets/images/cards/openpay.svg';
import secureIcon from '../../../../assets/images/cards/proteger.png';


const FormPayView = () => {
  const toast = useRef(null);
  const dispatch = useDispatch();
  const { functions, states } = useFormPayHook();
  const { onSubmit, getCodigoPostal, getBuscaCorreo, postValidateEmail, postInsertCart, getSearchCard } = functions;
  const { getDataFetch: validaCodigoPostaal } = useLazyFetch();
  const { openpay } = states;
  const [inputCorreo, setInputCorreo] = useState('');
  const [sameUser, setSameUser] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [isValidateEmail, setIsValidateEmail] = useState(false);
  const [token, setTokens] = useState('');
  const [mensajePago1, setMensajePago1] = useState('Para empezar a pagar');
  const [mensajePago2, setMensajePago2] = useState('Ingrese su correo electrónico...!');
  const [confirmDialog, setConfirmDialog] = useState(false);

  const optionsPromo = ['Si', 'No'];
  const [promociones, setPromociones] = useState(optionsPromo[0]);

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [colonias, setColonias] = useState([]);

  const cookie = new Cookies(null, { path: '/', maxAge: 86400 });
  const zipCode = cookie.get('zipCode');
  const [codigoPostal, setCodigoPostal] = useState('');
  const [zipCodeError, setZipCodeError] = useState('');
  const [zipCodeDialog, setZipCodeDialog] = useState(false);
  const [dialogTerminos, setDialogTerminos] = useState(false);


  const {
    handleSubmit,
    control,
    register,
    trigger,
    setValue,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      customerId: '',
      idCustomerOpenPay: '',
      name: '',
      apa: '',
      am: '',
      email: '',
      phone: '',
      calle: '',
      numInt: '',
      numExt: '',
      colonia: '',
      estado: '',
      delegacion: '',
      cp: zipCode,
      cardName: '',
      cardNumber: '',
      vigencia: '',
      cvv2: '',
      idCard: '',
      promos: '',
      carrito: JSON.parse(localStorage.getItem('shoppingCart')),
      totalCarrito: '2',
      maskCardNumber: '',
      referencia: '',
      mes: '',
      year: ''
    }
  });

  useEffect(() => {
    handleInputChangeCp({ target: { value: zipCode } });
  }, []);


  const validateCP = async (CP) => {
    const { data, errorFetch } = await validaCodigoPostaal(`/public/catalogs/valid-find-by-cp/${CP}`, 'GET', {});
    //console.log(data);
    if (data) {
      return data;
    }
    if (errorFetch) {
      console.error(errorFetch);
      return errorFetch;
    }
  }

  const handleZipCodeValidation = async (code) => {
    if (code.length === 5) {
      await validateCP(code).then((data) => {
        //console.log(data);
        if (data) {
          cookie.set('zipCode', code);
          setValue('cp', code);
          setCodigoPostal(code);
          setZipCodeError('');
          setZipCodeDialog(false);
        } else {
          setZipCodeError('Código Postal no válido.');
        }
      })
        .catch((error) => {
          console.error(error);
          setZipCodeError('Error al validar el Código Postal.');
        }).finally(() => {
          handleInputChangeCp({ target: { value: code } })
        });
    } else {
      setZipCodeError('Por favor ingresa un código postal válido de 5 dígitos.');
    }
  };

  const getFormErrorMessage = (name) => {
    const errorSmall = (
      <div>
        {errors[name] && (
          <Message
            text={errors[name].message}
            severity="error"
            style={{ width: '100%', marginTop: '.3rem', }}
          ></Message>
        )}
      </div>
    );
    return errorSmall;
  };

  const handleNextClick = async () => {

    let numero = getValues('phone').replace(new RegExp(' ', 'g'), '').replace(new RegExp('-', 'g'), '').replace(new RegExp('\\(', 'g'), '').replace(new RegExp('\\)', 'g'), '');
    setValue('phone', numero);
    //console.log(numero);
    const isValid = await trigger();
    if (isValid) {
      setActiveIndex(prevIndex => prevIndex + 1);
    }
  };

  const handlePrevClick = () => {
    setActiveIndex(prevIndex => prevIndex - 1);
  };

  const aceptarTerminos = (e) => {
    e.preventDefault();
    setDialogTerminos(true);

  }

  const handleDisabled = async (e) => {
    e.preventDefault();
    let customerValid = false;
    let customerId = null;
    await getBuscaCorreo(inputCorreo).then((data) => {

      setMensajePago1('Correo por validar');
      setMensajePago2('Revise su bandeja de entrada y valide su correo...!');

      if (!data.existCustomer) {
        showMessage('INFORMATIVO: Correo Electronico', 'Correo no registrado', 'info');
        setIsValidateEmail(true);
        return;
      }


      if (!data.customer.validatedCustomer) {
        showMessage('INFORMATIVO: Correo Electronico', 'Validar correo registrado', 'warn');
        setIsValidateEmail(true);
      }

      if (data.existCustomer && data.customer.validatedCustomer) {
        customerValid = true;
        customerId = data.customer.customerId;

        setValue('customerId', data.customer.customerId || null);
        setValue('idCustomerOpenPay', data.customer.idCustomerOpenPay || null);
        setValue('name', data.customer.firstName || null);
        setValue('name', data.customer.firstName || null);
        setValue('apa', data.customer.lastName || null);
        setValue('phone', data.customer.phone || null);
        setValue('email', inputCorreo);
        // if (data.addresses != null) {
        // setValue('calle', data.addresses[0].street);
        // setValue('numExt', data.addresses[0].externalNumber);
        // setValue('numInt', data.addresses[0].internalNumber);
        // setValue('cp', data.addresses[0].zipCode);
        //   handleInputChangeCp({ target: { value: `${data.addresses[0].zipCode}` } });
        // }
        //console.log("CP de entrada",getValues('cp'))
        if (getValues('cp') !== '' && getValues('cp') !== null && getValues('cp') !== undefined) {
          //console.log('existe cp')
          handleInputChangeCp({ target: { value: getValues('cp') } });
        }
        showMessage('INFORMATIVO: Correo Electronico', 'Registro encontrado', 'success');
        setIsValidateEmail(false);
        setIsVisible(!isVisible);
        setMensajePago1('Avancemos con su Pago');
        setMensajePago2('Gracias....!');
      }

    }).catch((error) => {
      customerValid = false;
      console.error(error);
      setMensajePago1('Error del Servidor');
      setMensajePago2('Inténtelo más tarde...!');
      showMessage('Error del Servidor', 'Hubo un problema con el servidor. Inténtelo más tarde.', 'error');
    })

    if (!customerValid) return;

    let cartDetails = JSON.parse(localStorage.getItem('shoppingCart'));

    const updatedCartDetails = cartDetails.map(item => {
      const { id, ...rest } = item;
      return {
        fkProductId: id,
        ...rest
      };
    });

    const rq = {
      "fkCustomerId": customerId,
      "cardDetails": updatedCartDetails
    }

    await postInsertCart(rq).then((data) => {
      console.log(data);
    }).catch((error) => {
      console.error(error);
    })

    // // buscar tarjetas //TODO dividir logica
    // await getSearchCard(getValues('idCustomerOpenPay')).then((data) => {
    //   console.log(data);
    // }).catch((error) => {
    //   console.error(error);
    // })
  };

  const handleInputChange = (e) => {
    setInputCorreo(e.target.value);
  };

  const handleSameUser = (e) => {
    //console.log(e.value);
    setSameUser(e.value);

    const name = getValues('name');
    const lastName = getValues('apa');
    const secondLastName = getValues('am');

    const concatName = `${name} ${lastName} ${secondLastName}`;
    if (e.value) {
      setValue('cardName', concatName);
    } else {
      setValue('cardName', '');
    }
  };

  const handleInputChangeCp = async (e) => {
    const { value } = e.target;
    let stringCP = value.toString();
    setCodigoPostal(stringCP);
    if (stringCP.length === 5) {
      //console.log('Busco calles');
      await getCodigoPostal(stringCP).then((data) => {
        //console.log(data);
        setValue('estado', data[0].ddEstado);
        setValue('delegacion', data[0].ddMnpio);
        setValue('colonia', data[0].ddAsenta);
        setColonias(data);
        //console.log(data);
      })
        .catch((error) => {
          console.error(error);
          showMessage('Error: Código Postal', 'No se encontro el Código Postal', 'error');
          setValue('estado', '');
          setValue('delegacion', '');
          setValue('colonia', '');
        })
        .finally(() => { setValue('cp', stringCP); });
    }
  };

  const handlePromos = async (e) => {
    const { value } = e.target;
    setPromociones(value);
    setValue('promos', value === 'Si' ? true : false);
  };

  const showMessage = (header, messError, severity) => {
    if (toast.current) {
      toast.current.show({ severity: severity, summary: header ? header : 'Error', detail: messError, life: 3000 });
    }
  };

  const steps = [
    {
      icon: 'pi pi-user',
      label: 'Personal',
      template: (item) => itemRenderer(item, 0)
    },
    {
      icon: 'pi pi-home',
      label: 'Direccion',
      template: (item) => itemRenderer(item, 1)
    },
    {
      icon: 'pi pi-credit-card',
      label: 'Tarjeta',
      template: (item) => itemRenderer(item, 2)
    }
  ];

  const itemRenderer = (item, itemIndex) => {
    const isActiveItem = activeIndex === itemIndex;
    const backgroundColor = isActiveItem ? 'var(--primary-color)' : 'var(--surface-b)';
    const textColor = isActiveItem ? 'var(--surface-b)' : 'var(--text-color-secondary)';

    return (
      <>
        <div className="mb-5">
          <span
            className="inline-flex flex-col align-items-center  justify-content-center border-circle border-primary border-1 h-3rem w-3rem z-1 cursor-pointer relative"
            style={{ backgroundColor: backgroundColor, color: textColor, marginTop: '-25px' }}
            onClick={() => setActiveIndex(itemIndex)}
          >
            <i className={`${item.icon} text-xl`} />
          </span>
        </div>
        <div>
          <span className="text-ms absolute bottom-0 left-0 right-0 top-5 text-center mt-5">
            {item.label}
          </span>
        </div>
      </>
    );
  };

  const handlePaste = (e) => {
    e.preventDefault();
    //console.log('Pegar texto');
    const paste = (e.clipboardData || window.clipboardData).getData('text');
    if (paste.length <= 6) {
      setTokens(paste);
    } else {
      // Manejo opcional: si el texto pegado es más largo que el OTP, manejarlo de alguna manera
      console.log('Texto pegado demasiado largo');
    }
  };

  const customInput = ({ events, props }) => {
    return (
      <input
        {...events}
        {...props}
        type="text"
        className="custom-otp-input-sample"
        onPaste={handlePaste}
      />
    );
  };

  const metodoEnvio = async () => {
    setError(false);
    setSuccess(false);
    let mes = ""+getValues('mes');
    let year = ""+getValues('year');
    let vigencia = ''+mes.padStart(2, '0') + '/' +year.padStart(2, '0');
    setValue('vigencia',vigencia);
    try {
      // Envolvemos handleSubmit(onSubmit) en una nueva promesa
      const result = await new Promise((resolve, reject) => {
        handleSubmit(async (data) => {
          try {
            const response = await onSubmit(data);
            resolve(response); // Resolvemos la promesa con el resultado
          } catch (error) {
            reject(error); // Rechazamos la promesa si hay un error
          }
        })();
      });

      if (result.error) {
        setError(true);
        console.error("Error en metodoEnvio:", result.error);
      } else {
        setSuccess(true);
        dispatch(clearCart());
        setIsVisible(false);

        console.log(result);
      }
    } catch (error) {
      setError(true);
      console.error("Error en metodoEnvio:", error);
    }
    setConfirmDialog(true);
    //console.log("sali")

  };


  const handleToken = async () => {
    //console.log(token);
    let customerId = null;
    await postValidateEmail(inputCorreo, token).then((data) => {
      //console.log(data);

      if (data?.validatedCustomer) {
        //console.log("if", data);
        customerId = data.customerId.toString();
        setValue('customerId', data.customerId.toString() || null);
        setMensajePago1('Avancemos con su Pago');
        setMensajePago2('Gracias....!');
        setValue('email', inputCorreo);
        setIsValidateEmail(false);
        setIsVisible(!isVisible);
      } else {
        showMessage('Error: Codigo', 'Codigo invalido', 'error');
        setMensajePago1('Error de codigo');
        setMensajePago2('Intente de nuevo...!');
      }

    }).catch((error) => {
      console.error(error);
    })
    //console.log(customerId);

    if (customerId === null) return;

    let cartDetails = JSON.parse(localStorage.getItem('shoppingCart'));

    const updatedCartDetails = cartDetails.map(item => {
      const { id, ...rest } = item;
      return {
        fkProductId: id,
        ...rest
      };
    });

    const rq = {
      "fkCustomerId": customerId,
      "cardDetails": updatedCartDetails
    }

    await postInsertCart(rq).then((data) => {
      console.log(data);
    }).catch((error) => {
      console.error(error);
    })
  }

  const [tipoTarjeta, setTipoTarjeta] = useState('');

  const changeNumberCard = (value) => {
    let numero = value.target.value.replace(new RegExp('-', 'g'), '');
    //console.log(numero);
    let regexVisa = /^[4]\d{0,15}$/
    let regexMaster = /^[5]\d{0,15}$/
    let regexAmex = /^34\d{0,13}$/

    setValue('cardNumber', numero);

    if (regexAmex.test(numero)) {
      setTipoTarjeta('A');
      setValue('maskCardNumber', value.target.value.replace(/(\d{4})(\d{6})(\d{5})/, '$1-$2-$3'));
      return;
    }
    if (regexMaster.test(numero)) {
      setTipoTarjeta('M');
      setValue('maskCardNumber', value.target.value.replace(/(\d{4})(?=\d)/g, '$1-'));
      return;
    }
    if (regexVisa.test(numero)) {
      setTipoTarjeta('V');
      setValue('maskCardNumber', value.target.value.replace(/(\d{4})(?=\d)/g, '$1-'));
      return;
    }
    setValue('maskCardNumber', value.target.value);
    setTipoTarjeta('');

  }

  return (
    <>
      <Toast ref={toast} />
      <ConfirmDialog closable={false} visible={confirmDialog} reject={(e) => { setConfirmDialog(false); }} accept={(e) => { setConfirmDialog(false) }} rejectLabel="salir" acceptLabel="Continuar" header={success ? '!Operación exitosa¡' : '!Error¡'} message={success ? 'Tu pago se ha realizado correctamente.' : 'Hubo un problema al procesar tu pago. Por favor, intenta nuevamente.'}></ConfirmDialog>
      <div className="grid mt-2 h-13rem w-full" style={{ overflow: 'hidden' }}>
        <img src={'https://www.shutterstock.com/image-vector/long-banner-set-doodle-seafood-260nw-2188553415.jpg'} alt={'bannerBottom'} className="w-full h-15rem" style={{ objectFit: 'cover' }} />
      </div>

      <div id="correo" className={` grid mt-5 animation-duration-500 box ${!success ? 'fadein' : 'fadeout hidden'} grid mt-8`} >
        {/* <div id="correo" className={`animation-duration-500 box ${!isVisible ? 'fadein' : 'fadeout hidden'} grid mt-8` } > */}
        <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3">
          {/* <h1 className={`animation-duration-500 box ${!isVisible ? 'fadein' : 'fadeout hidden'} text-center `} >Para empezar a pagar</h1> */}
          <h1 className="text-center">{mensajePago1}</h1>
          <h1 className="text-center">{mensajePago2}</h1>
          <form onSubmit={aceptarTerminos} >
            <div className="p-inputgroup">
              <span className="p-inputgroup-addon" style={{ backgroundColor: 'white' }}>
                <i className="pi pi-spin pi-at"></i>
              </span>
              <InputText
                className="p-inputtext-lg text-center"
                type="email"
                placeholder="Correo Electrónico"
                value={inputCorreo}
                onChange={handleInputChange}
                disabled={isVisible}
              />
              <Button
                icon="pi pi-search"
                className="p-button-warning p-5"
                size="large"
                disabled={inputCorreo.length <= 10 || isVisible}
                type="submit"
              />
            </div>
          </form>
        </div>
      </div>




      <div id="registro" className={`animation-duration-500 box ${isVisible ? 'fadein' : 'fadeout hidden'} grid mt-8`} >
        <div className="col-12 md:col-12 lg:col-6 lg:col-offset-3">
          <form onSubmit={handleSubmit(metodoEnvio)} >
            <Steps model={steps} activeIndex={activeIndex} readOnly={true} />
            {activeIndex === 0 && (
              <>
                <div className="border-2 border-dashed surface-border border-round mt-3 font-medium">
                  <div className="formgrid grid pt-5 lg:px-4 lg:py-5">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="name"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="name">Nombres</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" onClick={() => { handleSameUser({ value: false }) }} />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>

                    <div className="col-12 lg:col-4">
                      <Controller
                        name="apa"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="apa">Apellido Paterno</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="am"
                        control={control}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="am">Apellido Materno</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="formgrid grid pt-5 lg:px-4 lg:py-1">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="email"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="email">Correo</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="phone"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="phone">Celular </label><small> (10 dígitos)</small>
                            <InputMask {...field} mask="(99) 99-99-99-99" placeholder="(99) 99-99-99-99" className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="promos"
                        control={control}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="promos">Recibir Promociones</label>
                            <SelectButton {...field} className="flex gap-2 " value={promociones} onChange={handlePromos} options={optionsPromo} />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-end p-2">
                  <Button className="p-2" label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={handleNextClick} />
                </div>
              </>
            )}
            {activeIndex === 1 && (
              <>
                <div className="border-2 border-dashed surface-border border-round mt-3 font-medium">
                  <div className="formgrid grid pt-5 lg:px-4 lg:py-5">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="calle"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="calle">Calle</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-2">
                      <Controller
                        name="numExt"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="numExt">Número exterior</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-2">
                      <Controller
                        name="numInt"
                        control={control}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="numInt">Número interior</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="cp"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <label htmlFor="cp">Código Postal</label>
                            <div className="flex flex-wrap">
                              <InputText {...field} disabled className="p-2 focus:border-primary flex-1" keyfilter="num" maxLength={5} value={codigoPostal} />
                              <Button label="cambiar" className="flex-0" onClick={(e) => { e.preventDefault(); setZipCodeDialog(true) }}></Button>
                              {getFormErrorMessage(field.name)}
                            </div></>
                        )}
                      />
                    </div>
                    <div className="col-12">
                      <Controller
                        name="referencia"
                        control={control}

                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="referencia">Referencias</label>
                            <InputText {...field} className="p-2 focus:border-primary w-full" />
                          </div>
                        )}
                      />
                    </div>
                  </div>

                  <div className="formgrid grid pt-5 lg:px-4 lg:py-1">
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="estado"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="estado">Estado</label>
                            {/* <Dropdown {...field} className="w-full p-2" options={state} placeholder="Estado" optionLabel="name" itemTemplate={countryOptionTemplate} highlightOnSelect={true} /> */}
                            <InputText disabled {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="delegacion"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="delegacion">Delegacion/Municipio</label>
                            <InputText disabled {...field} className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-4">
                      <Controller
                        name="colonia"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="colonia">Colonia</label>
                            <Dropdown {...field} options={colonias} optionLabel="ddAsenta" optionValue="ddAsenta" className="p-2 focus:border-primary w-full" />
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-between">
                  <Button className="p-2" label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={handlePrevClick} />
                  <Button className="p-2" label="Next" icon="pi pi-arrow-right" iconPos="right" onClick={handleNextClick} />
                </div>


              </>
            )}

            {activeIndex === 2 && (
              <>
                <Panel header="Tarjeta de crédito o débito">
                  <div className="grid">
                    <div className="col-4">
                      <p>Tarjetas de crédito</p>
                      <div className="flex flex-wrap column-gap-3">
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={visa} style={{ height: '4rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={masterCard} style={{ height: '4rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={amex} style={{ height: '4rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={carnet} style={{ height: '3rem' }}></img>
                      </div>
                    </div>
                    <div className="col-8">
                      <p>Tarjetas de débito</p>
                      <div className="flex flex-wrap align-items-center column-gap-1 justify-content-center">
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={bbva} style={{ height: '4rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={santander} style={{ height: '5rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={hsbc} style={{ height: '7rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={scotia} style={{ height: '4rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={inbursa} style={{ height: '4rem' }}></img>
                        <img onContextMenu={(e)=>{e.preventDefault()}} src={ixe} style={{ height: '2.5rem' }}></img>
                      </div>
                    </div>
                    <div className="col-12 lg:col-6">
                      <Controller
                        name="cardName"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <div className="field col">
                              <label htmlFor="cardName">Nombre del titular</label>
                              <IconField iconPosition="left">
                                <InputIcon className="pi pi-user"> </InputIcon>
                                <InputText {...field} placeholder="Como aparece en la tarjeta" defaultValue={getValues('name')} className="p-2 focus:border-primary w-full pl-6" autoComplete="off" data-openpay-card="holder_name" />
                              </IconField>
                              {/* <ToggleButton onLabel="Mismo nombre personal" offLabel="Mismo nombre personal" onIcon="pi pi-check" offIcon="pi pi-times" checked={sameUser} onChange={handleSameUser} className="col-12 text-center" /> */}
                              {getFormErrorMessage(field.name)}
                            </div>
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-6">
                      <Controller
                        name="maskCardNumber"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="maskCardNumber">Número de tarjeta</label>
                            <div className="flex w-full">
                              <div className="flex-1 align-content-center pt-0">
                                <IconField iconPosition="left">
                                  <InputIcon className="pi pi-credit-card"> </InputIcon>
                                  <InputText {...field} className="p-2 focus:border-primary w-full pl-6" keyfilter="num" maxLength={tipoTarjeta === 'A' ? 17 : 19} autoComplete="off" data-openpay-card="card_number"
                                    onChange={changeNumberCard}

                                  />
                                </IconField>
                              </div>
                              <div className="flex-0 align-content-center">
                                {tipoTarjeta !== '' && <img src={tipoTarjeta === 'V' ? iconVisa : tipoTarjeta === 'M' ? iconMaster : tipoTarjeta === 'A' ? iconAmex : null}></img>}
                              </div>
                            </div>
                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-6">
                      <label htmlFor="cardName" className="w-full">Fecha de expiración</label>
                      <div className="flex flex-wrap justify-content-between mt-2">
                        <Controller
                          name="mes"
                          control={control}
                          rules={{
                            required: 'Campo requerido'
                          }}
                          render={({ field }) => (
                            <>


                              <div className="">
                                <InputText {...field} keyfilter={'num'} placeholder="Mes" maxLength={2} defaultValue={getValues('mes')} className="focus:border-primary w-auto" autoComplete="off" data-openpay-card="holder_mes" />
                                {/* <ToggleButton onLabel="Mismo nombre personal" offLabel="Mismo nombre personal" onIcon="pi pi-check" offIcon="pi pi-times" checked={sameUser} onChange={handleSameUser} className="col-12 text-center" /> */}
                                {getFormErrorMessage(field.name)}
                              </div>
                            </>
                          )}
                        />
                        <Controller
                          name="year"
                          control={control}
                          rules={{
                            required: 'Campo requerido'
                          }}
                          render={({ field }) => (
                            <>
                              <div className="">
                                <InputText {...field} keyfilter={'num'} placeholder="Año" maxLength={2} defaultValue={getValues('year')} className="focus:border-primary w-auto" autoComplete="off" data-openpay-card="holder_year" />
                                {/* <ToggleButton onLabel="Mismo nombre personal" offLabel="Mismo nombre personal" onIcon="pi pi-check" offIcon="pi pi-times" checked={sameUser} onChange={handleSameUser} className="col-12 text-center" /> */}
                                {getFormErrorMessage(field.name)}
                              </div>
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-12 md:col-6">
                      <Controller
                        name="cvv2"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="">
                            <label htmlFor="cardName" className="w-full">Código de seguridad</label>
                            <div className="flex flex-wrap justify-content-between mt-2">
                              <div>
                                <IconField iconPosition="left">
                                  <InputIcon className="pi pi-lock"> </InputIcon>
                                  <InputText {...field} className="focus:border-primary" keyfilter="num" maxLength={3} autoComplete="off" data-openpay-card="cvv2" placeholder="3 dígitos" />
                                </IconField>
                                {getFormErrorMessage(field.name)}
                              </div>
                              <img src={cvvf} style={{ width: '4rem' }}></img>
                              <img src={cvvb} style={{ width: '4rem' }}></img>
                            </div>
                          </div>

                        )}
                      />
                    </div>
                    <div className="col-12">
                      <div className="flex flex-wrap w-full justify-content-end items-align-center">
                        <div className="flex flex-column">
                          <label className="w-full">Transaciones realizadas vía</label>
                          <img onContextMenu={(e)=>{e.preventDefault()}} src={openpayI} style={{ height: '3rem' }}></img>
                        </div>
                        <Divider layout="vertical"></Divider>
                        <div className="flex flex-wrap items-align-center" style={{ width: 'fit-content' }}>
                          <img onContextMenu={(e)=>{e.preventDefault()}} src={secureIcon} style={{ height: '3rem' }}></img>
                          <label className="w-20rem">Tus pagos se realizan de forma segura con encriptación de 256 bits</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex pt-4 justify-content-start">
                    <Button className="p-2" label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={handlePrevClick} />
                  </div>
                  <div className="grid mt-5">
                    <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3">
                      <Button className="col-12" size="large" label="PAGAR" type="submit" disabled={!isValid} />
                    </div>
                  </div>
                </Panel>
                {/* <div className="border-2 border-dashed surface-border border-round mt-3 font-medium">
                  <div className="formgrid grid pt-5 lg:px-4 lg:py-5">
                    <div className="col-12 lg:col-6">
                      <Controller
                        name="cardName"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <div className="field col">
                              <label htmlFor="cardName">Nombre del titular</label>
                              <IconField iconPosition="left">
                                <InputIcon className="pi pi-user"> </InputIcon>
                                <InputText {...field} defaultValue={getValues('name')} className="p-2 focus:border-primary w-full pl-6" autoComplete="off" data-openpay-card="holder_name" />
                              </IconField>
                              <ToggleButton onLabel="Mismo nombre personal" offLabel="Mismo nombre personal" onIcon="pi pi-check" offIcon="pi pi-times" checked={sameUser} onChange={handleSameUser} className="col-12 text-center" />
                              
                              {getFormErrorMessage(field.name)}
                            </div>
                          </>
                        )}
                      />
                    </div>
                    <div className="col-12 lg:col-6 mb-3">
                      <Controller
                        name="maskCardNumber"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <div className="field col">
                            <label htmlFor="maskCardNumber">Número de tarjeta</label>
                            <div className="flex w-full">
                              <div className="flex-1 align-content-center pt-0">
                                <IconField iconPosition="left">
                                  <InputIcon className="pi pi-credit-card"> </InputIcon>
                                  <InputText {...field} className="p-2 focus:border-primary w-full pl-6" keyfilter="num" maxLength={tipoTarjeta === 'A' ? 17 : 19} autoComplete="off" data-openpay-card="card_number"
                                    onChange={changeNumberCard}

                                  />
                                </IconField>
                              </div>
                              <div className="flex-0 align-content-center">
                                {tipoTarjeta !== '' && <img src={tipoTarjeta === 'V' ? iconVisa : tipoTarjeta === 'M' ? iconMaster : tipoTarjeta === 'A' ? iconAmex : null}></img>}
                              </div>
                            </div>

                            {getFormErrorMessage(field.name)}
                          </div>
                        )}
                      />
                    </div>


                  </div>

                  <div className="grid nested-grid px-4">
                    <div className="field col-12 lg:col-6">
                      <h4 className="text-center">Fecha de expiración</h4>
                    </div>
                  </div>

                  <div className="grid nested-grid px-4 ">
                    <div className="field col-12 md:col-6 ">
                      <Controller
                        name="vigencia"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <div className="align-items-center  justify-content-center">
                              
                              <InputMask  {...field} className="p-2 focus:border-primary col-12 text-center " mask="99/99" placeholder="99/99" autoComplete="off" data-openpay-card="expiration_year" />
                              {getFormErrorMessage(field.name)}
                            </div>
                          </>

                        )}
                      />
                    </div>
                    <div className="field col-12 md:col-6">
                      <Controller
                        name="cvv2"
                        control={control}
                        rules={{
                          required: 'Campo requerido'
                        }}
                        render={({ field }) => (
                          <>
                            <IconField iconPosition="left">
                              <InputIcon className="pi pi-lock"> </InputIcon>
                              <InputText {...field} className="p-2 focus:border-primary w-full pl-6" keyfilter="num" maxLength={3} autoComplete="off" data-openpay-card="cvv2" placeholder="cvv" />
                            </IconField>
                            {getFormErrorMessage(field.name)}
                          </>

                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex pt-4 justify-content-start">
                  <Button className="p-2" label="Back" severity="secondary" icon="pi pi-arrow-left" onClick={handlePrevClick} />
                </div>
                <div className="grid mt-5">
                  <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3">
                    <Button className="col-12" size="large" label="PAGAR" type="submit" disabled={!isValid} />
                  </div>
                </div> */}
              </>
            )}

          </form>
        </div>
      </div >

      {isValidateEmail && (
        <>

          <div className="col-12 md:col-6 lg:col-6 lg:col-offset-3 text-center">
            <div className="card flex justify-content-center">
              <style>
                {`
                    .custom-otp-input-sample {
                        width: 48px;
                        height: 48px;
                        font-size: 24px;
                        text-align: center;confirm2key
                        border: 1px solid var(--surface-400);
                        background: transparent;
                        outline: none;
                        margin: 0;
                        box-sizing: border-box;
                        transition: border-color 0.3s;
                    }

                    .custom-otp-input-sample:focus {
                        border-color: var(--primary-color);
                    }

                    .custom-otp-input-sample:not(:first-child) {
                        border-left: 1px solid var(--surface-400);
                    }

                    .custom-otp-input-sample:first-child {
                        border-top-left-radius: 12px;
                        border-bottom-left-radius: 12px;
                    }

                    .custom-otp-input-sample:last-child {
                        border-top-right-radius: 12px;
                        border-bottom-right-radius: 12px;
                    }
                `}
              </style>
              <div className="flex flex-column align-items-center">
                <p className="font-bold text-xl mb-2">Auntificacion de correo</p>
                <p className="text-color-secondary block mb-5">Por favor revice su correo e ingrese el codigo</p>
                <InputOtp
                  value={token}
                  onChange={(e) => setTokens(e.value)}
                  length={6}
                  inputTemplate={customInput}
                  style={{ gap: 0 }}
                />
                <div className="flex justify-content-between mt-5 align-self-stretch">
                  <Button label="Limpiar" onClick={() => setTokens('')} link className="p-0"></Button>
                  <Button className="p-2" label="Enviar" onClick={handleToken} disabled={token.length < 6}></Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <Dialog
        visible={dialogTerminos}
        style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
        header='Terminos y condiciones'
        onHide={() => { setDialogTerminos(false) }}
      >
        <div className="card">
          <p className="text-lg">He leído y acepto los términos y condiciones.</p>
          <Accordion >
            <AccordionTab header='Aviso de privacidad - La Fudería'>
              <div>
                <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPP), La Fuderia pone a su disposición el siguiente aviso de privacidad.</p>

                <p>La Fuderia, es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la LFPDPP, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.</p>

                <p>Además, informamos a usted que La Fuderia, tiene su domicilio ubicado en Gabino Barreda #94ª, Colonia San Rafael, Alcaldía Cuauhtémoc, CP 06470, CDMX.</p>

                <p>Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como para entender los servicios y/o pedidos que solicite:</p>

                <ul>
                  <li>Entrega de pescados y mariscos</li>
                  <li>Servicio al cliente (quejas, devoluciones, etc.)</li>
                </ul>

                <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>

                <ul>
                  <li>Nombre completo</li>
                  <li>Correo electrónico</li>
                  <li>Dirección de envío (calle, número, CP, colonia, Alcaldía, ciudad)</li>
                </ul>

                <p>Por otra parte, le informamos que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distinta a nosotros y serán utilizados exclusivamente para los fines señalados.</p>

                <p>Usted tiene en todo momento el derecho a conocer qué datos personales tenemos, para qué se utilizan y las condiciones del uso que le damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación). De igual forma, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación), así como también oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos son conocidos como derechos ARCO.</p>

                <p>Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través de un escrito simple a través de correo electrónico dirigido a la siguiente dirección preguuuntame.contacto@fuderia.com:</p>

                <p>Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO.</p>

                <p>En todo caso la respuesta a la solicitud se dará en un plazo de 15 días hábiles.</p>

                <p>Los datos de contacto de la persona o departamento de datos personales que está a cargo de dar trámite a las solicitudes ARCO, son los siguientes:</p>

                <ul>
                  <li>Nombre del responsable: Andrea Isabel Gómez Karnstedt.</li>
                  <li>Domicilio: Gabino Barreda 94A Col. San Rafael, CDMX.</li>
                  <li>Correo electrónico: preguuuntame.contacto@fuderia.com.</li>
                </ul>

                <p>Cabe mencionar que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales.</p>

                <p>De igual forma, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.</p>

                <p>Asimismo, usted deberá considerar que para ciertos fines la revocación de su consentimiento implica que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>

                <p>Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar una solicitud a través de un escrito libre en el siguiente correo: preguuuntame.contacto@fuderia.com</p>

                <p>Del mismo modo, podrá solicitar la información para conocer el procedimiento y requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal.</p>

                <p>En cualquier caso, la respuesta a las peticiones se dará a conocer en un plazo de 15 días hábiles.</p>

                <p>En razón de cambios de nuestro modelo de negocio, o por otras causas, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, sin embargo, usted puede solicitar información sobre si el mismo ha sufrido algún cambio a través del siguiente correo electrónico: preguuuntame.contacto@fuderia.com.</p>
              </div>
            </AccordionTab>
          </Accordion>
          <div className="flex folex-wrap justify-content-center column-gap-3">
            <Button security="error" label="Cancelar" onClick={(e) => { e.preventDefault(); setDialogTerminos(false) }}></Button>
            <Button severity="success" label="Aceptar" onClick={(e) => { e.preventDefault(); handleDisabled(e); setDialogTerminos(false); }}></Button>

          </div>
        </div>
      </Dialog>

      {zipCodeDialog && (
        <ValidZipCode
          onOpen={zipCodeDialog}
          onClose={() => { setZipCodeDialog(false); }}
          onValidate={(code) => handleZipCodeValidation(code)}
          errorMessage={zipCodeError}
        />
      )}

      {success && (
        <div className="flex flex-column align-items-center mt-5">
          <h2 >¡Operación Exitosa!</h2>
          <h2 className="text-color-secondary block mb-5">Tu pago se ha realizado correctamente.</h2>
        </div>
      )}

      {error && (
        <div className="flex flex-column align-items-center mt-5">
          <h2 >¡Error!</h2>
          <h2 className="text-color-secondary block mb-5">Hubo un problema al procesar tu pago. Por favor, intenta nuevamente.</h2>
        </div>
      )}

    </>
  );
};

export default FormPayView;
