import { DataView } from "primereact/dataview";
import useCartProductsViewerHook from "../hooks/useCartProductsViewerHook";

const CartProductsViewer = () => {
    const { states, functions } = useCartProductsViewerHook();
    const { listTemplate } = functions;
    const {  purchaseCart } = states;

    return (
        <>
            <DataView
                value={purchaseCart}
                listTemplate={listTemplate}
                layout={'list'}
                paginator
                rows={6}
            />
        </>
    );
}

export default CartProductsViewer;