import { TYPE_SHOP } from '../../types/shop/shopTypes';

export const addElementToCart = (element) => (dispatch) => {
  //const cart = JSON.parse(localStorage.getItem('shoppingCart')) || []; // Si el carrito no existe en localStorage, cart es un arreglo vacío
  //const newCart = [...cart, element]; // Agrega el nuevo elemento al carrito
  dispatch({ type: TYPE_SHOP.ADD_TO_SHOPPING_CART, payload: element });
  // No es necesario actualizar localStorage aquí, ya que el estado de Redux se actualiza y refleja en el componente automáticamente

  // Si deseas actualizar localStorage, puedes hacerlo fuera de la acción o en un middleware de Redux
  // localStorage.setItem('shoppingCart', JSON.stringify(newCart));
};

export const loadElementsFromStorage = () => (dispatch) => {
  const cart = JSON.parse(localStorage.getItem('shoppingCart')) || []; // Si el carrito no existe en localStorage, cart es un arreglo vacío
  dispatch({ type: TYPE_SHOP.RELOAD_SHOPPING_CART, payload: cart });
};

export const loadElementsToShoppingCart = (list) => (dispatch) => {
  dispatch({ type: TYPE_SHOP.ADD_TO_SHOPPING_CART, payload: list });
};


export const makeCart = (list) => (dispatch) => {
  const cart = JSON.parse(localStorage.getItem('shoppingCart'));
  let elements = [];
  try {
    elements = cart.map((item) => {
      const id = item.id;
      const quantity = item.quantity;
      const element = list.find((element) => element.productId === id);
      return { ...element, quantity };
    });
  } catch {
    elements=[];
  }
  dispatch({ type: TYPE_SHOP.MAKE_CART_PURCHASE, payload: elements });

}

export const removeElementFromCart = (element) => (dispatch) => {
  dispatch({ type: TYPE_SHOP.REMOVE_TO_SHOPPING_CART, payload: element });
}

export const addupElementToCart = (element) => (dispatch) => {
  dispatch({ type: TYPE_SHOP.ADD_ELEMENT_TO_CART, payload: element });
}

export const deleteElementFromCart = (element) => (dispatch) => {
  dispatch({ type: TYPE_SHOP.DELETE_ELEMENT_FROM_CART, payload: element });
}


export const clearCart = () => (dispatch) => {
  dispatch({ type: TYPE_SHOP.CLEAR_CART });
  localStorage.clear();
}