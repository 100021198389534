import { configureStore } from '@reduxjs/toolkit'; // Importa configureStore desde Redux Toolkit
import rootReducer from '../reducers'; // Importa tu rootReducer

const store = configureStore({
  reducer: rootReducer, // Pasamos el rootReducer como argumento
  devTools: process.env.REACT_APP_ENVIRONMENT !== 'PROD',
  // Aquí puedes configurar otras opciones de store si es necesario
});

export default store;
