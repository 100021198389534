import React, { useRef, useState } from "react";
import { InputText } from 'primereact/inputtext';
import { IconField } from "primereact/iconfield";
import { InputIcon } from "primereact/inputicon";
import { Button } from 'primereact/button';
import '../css/SectionSix.css';
import imgfauderia from '../../../../assets/images/lficon.png';

import Image01 from './carrusel/Img01.png';
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2";
import { Toast } from "primereact/toast";
import { useDispatch } from "react-redux";
import { setSectionPage } from "../../../../redux/actions/pageState/pageStateActions";
import { useNavigate } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Accordion, AccordionTab } from "primereact/accordion";

const SectionSix = ({ reference }) => {
    const [token, setToken] = useState("");
    const { getDataFetch } = useLazyFetch();
    const toast = useRef(null);
    const [validacion, setValidacion] = useState({
        captacha: true,
        email: true
    });
    const [dialogTerminos, setDialogTerminos] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const newletter = async () => {
        if (validar()) {
            let respuesta = await getDataFetch('/public/newsletter', 'POST', { rq: { email: email, is_active: 1 } });
            if (respuesta.errorFetch) {
                //console.log(respuesta.errorFetch)
                toast.current.show({
                    detail: 'No se logró registrar el correo, intentelo más tarde',
                    summary: 'Error',
                    severity: 'error'
                });

            } else {
                toast.current.show({
                    detail: 'Correo registrado, espere por nuevas promociones',
                    summary: 'Éxito',
                    severity: 'success'
                });

                captchaRef.current.resetCaptcha();
                setEmail('');
            }
        }
    }

    const validar = () => {
        let auxVal = validacion;
        if (email !== null && email !== '')
            auxVal.email = true;
        else
            auxVal.email = false
        if (token !== null && token !== '')
            auxVal.captacha = true
        else
            auxVal.captacha = false
        setValidacion({ ...auxVal });
        return auxVal.email && auxVal.captacha
    }

    const changeSectionPage = (section) => {
        //navigate('/');

        dispatch(setSectionPage(section));
    }

    const captchaRef = useRef(null);
    return (
        <>
            <Toast ref={toast}></Toast>
            <div className="grid mx-2 lg:mx-8" ref={reference}>
                <div className="col-12  md:col-4 lg:col-4 lg:mt-0 flex flex-column justify-content-center">
                    <div className="flex justify-content-center">
                        <img src={imgfauderia} alt={Image01} className="w-5" />
                    </div>
                    <p className="footer-title">La Fudería.</p>
                    <div className="flex justify-content-evenly">
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="https://www.facebook.com/profile.php?id=61555553096533"><i className="pi pi-facebook" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="https://www.instagram.com/lafuderia/"><i className="pi pi-instagram" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="https://x.com/LaFuderia"><i className="pi pi-twitter" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                        <div className="inline font-bold text-center p-5 border-round"><a style={{ color: '#4b5563' }} href="http://www.youtube.com/@LaFuder%C3%ADa"><i className="pi pi-youtube" style={{ fontSize: '3rem', cursor: 'pointer' }}></i></a></div>
                    </div>

                </div>

                <div className="col-12  text-center md:col-4 lg:col-4 lg:mt-0">
                    <p className=" font-bold sitemap-title">SITEMAP</p>
                    <p onClick={() => { changeSectionPage('TWO') }} className="cursor-pointer hover:text-pink-400 font-bold sitemap">NOSOTROS</p>
                    <p onClick={() => { navigate('/products') }} className="hover:text-pink-400 cursor-pointer font-bold sitemap">PRODUCTOS</p>
                    <p onClick={()=>{setDialogTerminos(true)}} className="hover:text-pink-400 cursor-pointer font-bold sitemap">LEGALES</p>
                    <a href="mailto:preguuuntame@lafuderia.com" className="hover:text-pink-400 cursor-pointer font-bold sitemap">CONTACTO</a>
                </div>

                <div className="col-12  md:col-4 lg:col-4 lg:mt-0 px-3">
                    <p className="font-bold sitemap-title text-center lg:text-left">NEWSLETTER</p>
                    <p className="sitemap text-center lg:text-left">Suscríbete para recibir noticias, acceder a ofertas exclusivas y más.</p>
                    <div className="field">
                        <IconField className="col-12" iconPosition="left">
                            <InputIcon style={{ top: "1.5rem", left: '1rem' }} className="pi pi-spin pi-at"> </InputIcon>
                            <InputText value={email} className="col-12 p-inputtext-lg text-center" type="email" placeholder="Ingresa tu Correo Electrónico..." onChange={(e) => { setEmail(e.target.value) }} />
                        </IconField>
                    </div>
                    <div className="flex w-full justify-content-center">
                        <HCaptcha
                            ref={captchaRef}
                            sitekey="cc10d24c-4514-4416-aa06-7cc20f466d04"
                            onVerify={(token) => setToken(token)}
                            onExpire={e => setToken("")}
                        />
                    </div>
                    <div className="field">
                        <Button className="col-12" size="large" label="SUSCRIBIRSE" onClick={newletter} />
                    </div>
                    {!validacion.email && <p className="small-error">Ingrese un correo</p>}
                    <br />
                    {!validacion.captacha && <p className="small-error">Resuelva el captcha</p>}
                </div>
            </div>


            <div className="grid mt-2 h-13rem w-full" style={{ overflow: 'hidden' }}>
                <img src={'https://www.shutterstock.com/image-vector/long-banner-set-doodle-seafood-260nw-2188553415.jpg'} alt={'bannerBottom'} className="w-full h-15rem" style={{ objectFit: 'cover' }} />
            </div>
            <Dialog
                visible={dialogTerminos}
                style={{ width: '50vw' }} breakpoints={{ '960px': '75vw', '641px': '100vw' }}
                header='Terminos y condiciones'
                onHide={() => { setDialogTerminos(false) }}
            >
                <div className="card">
                    
                    <Accordion >
                        <AccordionTab header='Aviso de privacidad - La Fudería'>
                            <div>
                                <p>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPP), La Fuderia pone a su disposición el siguiente aviso de privacidad.</p>

                                <p>La Fuderia, es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la LFPDPP, a través de este instrumento se informa a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.</p>

                                <p>Además, informamos a usted que La Fuderia, tiene su domicilio ubicado en Gabino Barreda #94ª, Colonia San Rafael, Alcaldía Cuauhtémoc, CP 06470, CDMX.</p>

                                <p>Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como para entender los servicios y/o pedidos que solicite:</p>

                                <ul>
                                    <li>Entrega de pescados y mariscos</li>
                                    <li>Servicio al cliente (quejas, devoluciones, etc.)</li>
                                </ul>

                                <p>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</p>

                                <ul>
                                    <li>Nombre completo</li>
                                    <li>Correo electrónico</li>
                                    <li>Dirección de envío (calle, número, CP, colonia, Alcaldía, ciudad)</li>
                                </ul>

                                <p>Por otra parte, le informamos que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distinta a nosotros y serán utilizados exclusivamente para los fines señalados.</p>

                                <p>Usted tiene en todo momento el derecho a conocer qué datos personales tenemos, para qué se utilizan y las condiciones del uso que le damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación). De igual forma, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no está siendo utilizada adecuadamente (Cancelación), así como también oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos son conocidos como derechos ARCO.</p>

                                <p>Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través de un escrito simple a través de correo electrónico dirigido a la siguiente dirección preguuuntame.contacto@fuderia.com:</p>

                                <p>Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO.</p>

                                <p>En todo caso la respuesta a la solicitud se dará en un plazo de 15 días hábiles.</p>

                                <p>Los datos de contacto de la persona o departamento de datos personales que está a cargo de dar trámite a las solicitudes ARCO, son los siguientes:</p>

                                <ul>
                                    <li>Nombre del responsable: Andrea Isabel Gómez Karnstedt.</li>
                                    <li>Domicilio: Gabino Barreda 94A Col. San Rafael, CDMX.</li>
                                    <li>Correo electrónico: preguuuntame.contacto@fuderia.com.</li>
                                </ul>

                                <p>Cabe mencionar que en cualquier momento usted puede revocar su consentimiento para el uso de sus datos personales.</p>

                                <p>De igual forma, usted puede revocar el consentimiento que, en su caso, nos haya otorgado para el tratamiento de sus datos personales.</p>

                                <p>Asimismo, usted deberá considerar que para ciertos fines la revocación de su consentimiento implica que no podamos seguir prestando el servicio que nos solicitó, o la conclusión de su relación con nosotros.</p>

                                <p>Para revocar el consentimiento que usted otorga en este acto o para limitar su divulgación, se deberá presentar una solicitud a través de un escrito libre en el siguiente correo: preguuuntame.contacto@fuderia.com</p>

                                <p>Del mismo modo, podrá solicitar la información para conocer el procedimiento y requisitos para la revocación del consentimiento, así como limitar el uso y divulgación de su información personal.</p>

                                <p>En cualquier caso, la respuesta a las peticiones se dará a conocer en un plazo de 15 días hábiles.</p>

                                <p>En razón de cambios de nuestro modelo de negocio, o por otras causas, nos comprometemos a mantenerlo informado sobre los cambios que pueda sufrir el presente aviso de privacidad, sin embargo, usted puede solicitar información sobre si el mismo ha sufrido algún cambio a través del siguiente correo electrónico: preguuuntame.contacto@fuderia.com.</p>
                            </div>
                        </AccordionTab>
                    </Accordion>
                    <div className="flex folex-wrap justify-content-center column-gap-3">
                        <Button severity="success" label="Aceptar" onClick={(e) => { setDialogTerminos(false); }}></Button>

                    </div>
                </div>
            </Dialog>
        </>
    );
}

export default SectionSix;