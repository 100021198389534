import { TYPE_PAGE_STATE } from "../../types/PageState/PageStateTypes";

const initialState = {
    sectionPage: 'ONE',
    productDetail: localStorage.getItem('ProductDetail') ? JSON.parse(localStorage.getItem('ProductDetail')) : {},
    filtersSelect:[],
    filters:[],
    rangePrice:[0,1000],
    rangeGR:[0,1000],
    rangePriceKG:[0,1000],
    category:[],
    categorySelect:[],
    subcategorySelect:[],
    subcategory:[],
}

const pageStateReducer = (state = initialState, action) => {
    switch (action.type) {
        case TYPE_PAGE_STATE.SCROLL_VIEW: {
            return {
                ...state,
                sectionPage: action.payload,
            };
        }
        case TYPE_PAGE_STATE.PRODUCT_DETAIL: {
            localStorage.setItem('ProductDetail', JSON.stringify(action.payload))
            return {
                ...state,
                productDetail: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_AUXfILTERS: {
            
            return {
                ...state,
                filtersSelect: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_FILTERS: {
            return {
                ...state,
                filters: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_PRICE_ITEM: {
            return {
                ...state,
                rangePrice: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_SAMPLE_KG: {
            return {
                ...state,
                rangeGR: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_PRICE_KG: {
            return {
                ...state,
                rangePriceKG: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_CATEGORY_ITEM: {
            return {
                ...state,
                category: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_CATEGORY_SELECT_ITEM: {
            return {
                ...state,
                categorySelect: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_SUBCATEGORY_SELECT_ITEM: {
            return {
                ...state,
                subcategorySelect: action.payload,
            };
        }
        case TYPE_PAGE_STATE.SET_SUBCATEGORY_ITEM: {
            return {
                ...state,
                subcategory: action.payload,
            };
        }
        default:
            return state;
    }
}

export default pageStateReducer;