import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
import App from './App';
import store from './redux/stores/store';
import es from './idioms/es.json';
import en from './idioms/en.json';
import reportWebVitals from './reportWebVitals';

const configLng = {
  interpolation: { escapeValue: false },
  lng: 'es',
  resources: {
    es: { translation: es },
    en: { translation: en },
  },
};

i18next.init(configLng);

const root = createRoot(document.getElementById('root'));
root.render(
  <HashRouter>
    <I18nextProvider i18n={i18next}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>
  </HashRouter>,
);

// Si deseas medir el rendimiento de tu aplicación, puedes usar reportWebVitals
reportWebVitals();
