import { useContext, useRef, useEffect } from 'react';
import { LayoutContext } from './context/layoutcontext';
import { useEventListener } from 'primereact/hooks';
import { classNames, DomHandler } from 'primereact/utils';

import AppTopbar from './AppTopbar';
import AppSidebar from './AppSidebar';
import AppFooter from './AppFooter';
import AppConfig from './AppConfig';

const Layout = (props) => {
  const { layoutConfig, layoutState, setLayoutState } = useContext(LayoutContext);
  // const topbarRef = useRef(null);
  // const sidebarRef = useRef(null);
  // // const [bindMenuOutsideClickListener, unbindMenuOutsideClickListener] =
  // //   useEventListener({
  // //     type: 'click',
  // //     listener: (event) => {
  // //       const isOutsideClicked = !(
  // //         sidebarRef.current.isSameNode(event.target) ||
  // //         sidebarRef.current.contains(event.target) ||
  // //         topbarRef.current.menubutton.isSameNode(event.target) ||
  // //         topbarRef.current.menubutton.contains(event.target)
  // //       );

  // //       if (isOutsideClicked) {
  // //         hideMenu();
  // //       }
  // //     },
  // //   });

  // // const [unbindProfileMenuOutsideClickListener] = useEventListener({
  // //   type: 'click',
  // //   listener: (event) => {
  // //     const isOutsideClicked = !(
  // //       topbarRef.current.topbarmenu.isSameNode(event.target) ||
  // //       topbarRef.current.topbarmenu.contains(event.target) ||
  // //       topbarRef.current.topbarmenubutton.isSameNode(event.target) ||
  // //       topbarRef.current.topbarmenubutton.contains(event.target)
  // //     );

  // //     if (isOutsideClicked) {
  // //       hideProfileMenu();
  // //     }
  // //   },
  // // });

  // const blockBodyScroll = () => {
  //   DomHandler.addClass('blocked-scroll');
  // };

  // const hideProfileMenu = () => {
  //   setLayoutState((prevLayoutState) => ({
  //     ...prevLayoutState,
  //     profileSidebarVisible: false,
  //   }));
  //   unbindProfileMenuOutsideClickListener();
  // };

  // const unblockBodyScroll = () => {
  //   DomHandler.removeClass('blocked-scroll');
  // };

  // const hideMenu = () => {
  //   setLayoutState((prevLayoutState) => ({
  //     ...prevLayoutState,
  //     overlayMenuActive: false,
  //     staticMenuMobileActive: false,
  //     menuHoverActive: false,
  //   }));
  //   unbindMenuOutsideClickListener();
  //   unblockBodyScroll();
  // };

  // useEffect(() => {
  //   if (layoutState.overlayMenuActive || layoutState.staticMenuMobileActive) {
  //     bindMenuOutsideClickListener();
  //   }

  //   layoutState.staticMenuMobileActive && blockBodyScroll();
  // }, [
  //   layoutState.overlayMenuActive,
  //   layoutState.staticMenuMobileActive,
  //   bindMenuOutsideClickListener,
  // ]);

  const containerClass = classNames('layout-wrapper', {
    'layout-theme-light': layoutConfig.colorScheme === 'light',
    'layout-theme-dark': layoutConfig.colorScheme === 'dark',
    'layout-overlay': layoutConfig.menuMode === 'overlay',
    'layout-static': layoutConfig.menuMode === 'static',
    'layout-static-inactive':
      layoutState.staticMenuDesktopInactive &&
      layoutConfig.menuMode === 'static',
    'layout-overlay-active': layoutState.overlayMenuActive,
    'layout-mobile-active': layoutState.staticMenuMobileActive,
    'p-input-filled': layoutConfig.inputStyle === 'filled',
    'p-ripple-disabled': !layoutConfig.ripple,
  });

  return (
    <>
      <div>
        <div className='w-full text-center text-5xl p-1' style={{backgroundColor:'#f57591'}}>
          <label style={{color:'white'}}>¡Envios gratis a partir de $599!</label>
        </div>
        <AppTopbar />

        {/* <div ref={sidebarRef} className="layout-sidebar">
          <AppSidebar />
        </div> */}

        <div className="layout-main-container">
          <div>{props.children}</div>
          <AppFooter />
        </div>
        <AppConfig />
        <div className="layout-mask"></div>
      </div>
    </>
  );
};

export default Layout;
