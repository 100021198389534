import { forwardRef, useEffect, useState } from "react";
import { Galleria } from "primereact/galleria";
import '../css/SectionOne.css';
import '../../../../assets/css/fonts.css';
import Image01 from './carrusel/Img01.png';
import Image02 from './carrusel/Img02.png';
import Image03 from './carrusel/Img03.png';

const PhotoService = {
    getData() {
        return [
            {
                itemImageSrc: Image01,
                alt: 'Description for Image 1',
                title: 'Title 1'
            },
            {
                itemImageSrc: Image02,
                alt: 'Description for Image 2',
                title: 'Title 2'
            },
            {
                itemImageSrc: Image03,
                alt: 'Description for Image 2',
                title: 'Title 2'
            }

        ];
    },

    getImages() {
        return Promise.resolve(this.getData());
    }
};

const SectionOne = ({reference}) => {
    const [images, setImages] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isSliding, setIsSliding] = useState(false); // Estado para controlar la transición

    useEffect(() => {
        PhotoService.getImages().then(data => setImages(data));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsSliding(true); // Activar la transición al cambiar de imagen
            setActiveIndex(prevIndex => (prevIndex + 1) % (images ? images.length : 1));
            setTimeout(() => setIsSliding(false), 1000); // Desactivar la transición después de 1 segundo
        }, 5000);

        return () => clearInterval(interval);
    }, [images]);

    const itemTemplate = (item) => {
        return (
            <div className={`p-galleria-item-wrapper${isSliding ? ' img-sliding' : ''}`}>
                <img 
                    src={item.itemImageSrc} 
                    alt={item.alt} 
                    style={{ width: '100vw' }} 
                />
            </div>
        );
    };

    return (
        <div className="carousel-container" id='ONE' ref={reference}>
            <Galleria 
                value={images} 
                item={itemTemplate} 
                showThumbnails={false} 
                showIndicators 
                showIndicatorsOnItem 
                activeIndex={activeIndex}
                onItemChange={(e) => setActiveIndex(e.index)}
                transitionInterval={5000} // Duración de la transición en milisegundos (5 segundos)
            />
            <div className="carousel-footer">
                <p>¡Con nosotros, preparar platillos es pan comido! <span>Estás a un click, solo relájate y disfruta.</span></p>
            </div>
        </div>
    );
}

export default SectionOne;
