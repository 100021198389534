import { useEffect } from "react"
import SectionFive from "../../home/LandingPage/componets/SectionFive"
import SectionSix from "../../home/LandingPage/componets/SectionSix"
import ProductView from "./components/ProductView"

const GeneralProductView = () => {
    useEffect(()=>{
        window.scrollTo({
            top:0
        })
    })
    return (
        <>
            <ProductView></ProductView>
            <SectionFive></SectionFive>
            <SectionSix></SectionSix>
        </>
    )
}
export default GeneralProductView