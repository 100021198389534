import { Button } from "primereact/button"
import { useDispatch } from "react-redux"
import { setProductDetail } from "../../../../redux/actions/pageState/pageStateActions";
import { useNavigate } from "react-router-dom";
import useProductsViewHook from "../../shopContainer/hooks/useProductsViewHook";
import { addElementToCart, clearCart } from "../../../../redux/actions/shop/shopActions";
import Cookies from 'universal-cookie';
import { useState } from "react";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2";
import ValidZipCode from "../../shopContainer/components/ValidZipCode";

const ProductoCard = ({ Product }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { getDataFetch: validaCodigoPostaal } = useLazyFetch();
    const productHook = useProductsViewHook();
    const [zipCodeError, setZipCodeError] = useState('');
    const [zipCodeDialog, setZipCodeDialog] = useState('');
    const selectProduct = () => {
        dispatch(setProductDetail(Product));
        navigate('/product');
    }
    const cookie = new Cookies(null, { path: '/', maxAge: 86400 });
    function trimEndSpaces(str) {
        return str.replace(/\s+$/, '');
    }

    const addToCart = (product, elements = 1) => {
        const simpleElement = {
            id: product.productId.trim(),
            code: trimEndSpaces(product.productSku),
            quantity: elements,
        };
        dispatch(addElementToCart(simpleElement));
        const zipCodeCookie = cookie.get('zipCode');
        if (!zipCodeCookie) {
            setZipCodeError('');
            setZipCodeDialog(true);
            return;
        }
    };
    const getCodigoPostal = async (cp) => {
        const { data, errorFetch } = await validaCodigoPostaal(`/public/catalogs/valid-find-by-cp/${cp}`, 'GET', {});
        //console.log(data);
        if (data) {
            return data;
        }
        if (errorFetch) {
            console.error(errorFetch);
            return errorFetch;
        }
    };
    const handleZipCodeValidation = async (code) => {
        if (code.length === 5) {
            await getCodigoPostal(code).then((data) => {
                //console.log(data);
                if (data) {
                    cookie.set('zipCode', code);
                    setZipCodeError('');
                    setZipCodeDialog(false);
                } else {
                    setZipCodeError('Código Postal no válido.');
                }
            })
                .catch((error) => {
                    console.error(error);
                    setZipCodeError('Error al validar el Código Postal.');
                })
        } else {
            setZipCodeError('Por favor ingresa un código postal válido de 5 dígitos.');
        }
    };
    return (
        <div className="card-product p-3 h-full">
            <div className="flex w-12 img-product-container">
                <img onClick={selectProduct} src={Product.miniatureImageUrl ? Product.miniatureImageUrl : 'https://img.freepik.com/vector-gratis/plantilla-etiqueta-personaje-dibujos-animados-lindo-pez-aislado_1308-58808.jpg'} className="img-product"></img>
            </div>
            <div className="desc-product-view">
                <p className="name-product-view">{Product.productName}</p>
                <p className="subname-product-view">{Product.subCategoryName}</p>
                <p className="price-product-view">${Product.price} pz</p>
                <div className="mt-5 flex flex-wrap gap-2 justify-content-center mt-0">
                    <Button icon="pi pi-shopping-cart" rounded onClick={() => { addToCart(Product) }} />
                    {/* <Button icon="pi pi-heart" rounded /> */}
                    <Button icon="pi pi-share-alt" rounded severity="success" />
                </div>
            </div>
            {zipCodeDialog && (
                <ValidZipCode
                    onOpen={zipCodeDialog}
                    onClose={() => { setZipCodeDialog(false); if (!cookie.get('zipCode')) dispatch(clearCart()) }}
                    onValidate={(code) => handleZipCodeValidation(code)}
                    errorMessage={zipCodeError}
                />
            )}
        </div>
    )
}
export default ProductoCard