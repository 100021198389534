//import getConfig from 'next/config';
import PrimeReact from 'primereact/api';
import { Button } from 'primereact/button';
import { InputSwitch } from 'primereact/inputswitch';
import { RadioButton } from 'primereact/radiobutton';
import { Sidebar } from 'primereact/sidebar';
import { classNames } from 'primereact/utils';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { LayoutContext } from './context/layoutcontext';

import img1 from '../../assets/images/layout/themes/bootstrap4-light-blue.svg';
import img2 from '../../assets/images/layout/themes/bootstrap4-light-purple.svg';
import img3 from '../../assets/images/layout/themes/bootstrap4-dark-blue.svg';
import img4 from '../../assets/images/layout/themes/bootstrap4-dark-purple.svg';

import img5 from '../../assets/images/layout/themes/md-light-indigo.svg';
import img6 from '../../assets/images/layout/themes/md-light-deeppurple.svg';
import img7 from '../../assets/images/layout/themes/md-dark-indigo.svg';
import img8 from '../../assets/images/layout/themes/md-dark-deeppurple.svg';

const AppConfig = (props) => {
  const [scales] = useState([12, 13, 14, 15, 16]);
  const { layoutConfig, setLayoutConfig, layoutState, setLayoutState } =
    useContext(LayoutContext);

  /*
  const onConfigButtonClick = () => {
    setLayoutState((prevState) => ({
      ...prevState,
      configSidebarVisible: true,
    }));
  };*/

  const onConfigSidebarHide = () => {
    setLayoutState((prevState) => ({
      ...prevState,
      configSidebarVisible: false,
    }));
  };

  const changeInputStyle = (e) => {
    setLayoutConfig((prevState) => ({ ...prevState, inputStyle: e.value }));
  };

  const changeRipple = (e) => {
    PrimeReact.ripple = e.value;
    setLayoutConfig((prevState) => ({ ...prevState, ripple: e.value }));
  };

  const changeMenuMode = (e) => {
    setLayoutConfig((prevState) => ({ ...prevState, menuMode: e.value }));
  };

  const changeTheme = (theme, colorScheme) => {
    const themeLink = document.getElementById('theme-css');
    //console.log(themeLink);
    const themeHref = themeLink ? themeLink.getAttribute('href') : null;
    const newHref = themeHref
      ? themeHref.replace(layoutConfig.theme, theme)
      : null;

    replaceLink(themeLink, newHref, () => {
      setLayoutConfig((prevState) => ({ ...prevState, theme, colorScheme }));
    });
  };

  const replaceLink = (linkElement, href, onComplete) => {
    if (!linkElement || !href) {
      return;
    }

    const id = linkElement.getAttribute('id');
    const cloneLinkElement = linkElement.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    linkElement.parentNode.insertBefore(
      cloneLinkElement,
      linkElement.nextSibling,
    );

    cloneLinkElement.addEventListener('load', () => {
      linkElement.remove();

      const element = document.getElementById(id); // re-check
      element && element.remove();

      cloneLinkElement.setAttribute('id', id);
      onComplete && onComplete();
    });
  };

  const decrementScale = () => {
    setLayoutConfig((prevState) => ({
      ...prevState,
      scale: prevState.scale - 1,
    }));
  };

  const incrementScale = () => {
    setLayoutConfig((prevState) => ({
      ...prevState,
      scale: prevState.scale + 1,
    }));
  };

  const applyScale = useCallback(() => {
    document.documentElement.style.fontSize = layoutConfig.scale + 'px';
  }, [layoutConfig.scale]);

  useEffect(() => {
    applyScale();
  }, [layoutConfig.scale, applyScale]);

  return (
    <>
      {/*
      <button
        className="layout-config-button p-link"
        type="button"
        onClick={onConfigButtonClick}
      >
        <i className="pi pi-cog"></i>
      </button>
      */}

      <Sidebar
        visible={layoutState.configSidebarVisible}
        onHide={onConfigSidebarHide}
        position="right"
        className="layout-config-sidebar w-20rem"
      >
        <h5>Scale</h5>
        <div className="flex align-items-center">
          <Button
            icon="pi pi-minus"
            type="button"
            onClick={decrementScale}
            className="p-button-text p-button-rounded w-2rem h-2rem mr-2"
            disabled={layoutConfig.scale === scales[0]}
          ></Button>
          <div className="flex gap-2 align-items-center">
            {scales.map((item) => {
              return (
                <i
                  className={classNames('pi pi-circle-fill', {
                    'text-primary-500': item === layoutConfig.scale,
                    'text-300': item !== layoutConfig.scale,
                  })}
                  key={item}
                ></i>
              );
            })}
          </div>
          <Button
            icon="pi pi-plus"
            type="button"
            onClick={incrementScale}
            className="p-button-text p-button-rounded w-2rem h-2rem ml-2"
            disabled={layoutConfig.scale === scales[scales.length - 1]}
          ></Button>
        </div>

        {!props.simple && (
          <>
            <h5>Menu Type</h5>
            <div className="flex">
              <div className="field-radiobutton flex-1">
                <RadioButton
                  name="menuMode"
                  value={'static'}
                  checked={layoutConfig.menuMode === 'static'}
                  onChange={(e) => changeMenuMode(e)}
                  inputId="mode1"
                ></RadioButton>
                <label htmlFor="mode1">Static</label>
              </div>
              <div className="field-radiobutton flex-1">
                <RadioButton
                  name="menuMode"
                  value={'overlay'}
                  checked={layoutConfig.menuMode === 'overlay'}
                  onChange={(e) => changeMenuMode(e)}
                  inputId="mode2"
                ></RadioButton>
                <label htmlFor="mode2">Overlay</label>
              </div>
            </div>
          </>
        )}

        <h5>Input Style</h5>
        <div className="flex">
          <div className="field-radiobutton flex-1">
            <RadioButton
              name="inputStyle"
              value={'outlined'}
              checked={layoutConfig.inputStyle === 'outlined'}
              onChange={(e) => changeInputStyle(e)}
              inputId="outlined_input"
            ></RadioButton>
            <label htmlFor="outlined_input">Outlined</label>
          </div>
          <div className="field-radiobutton flex-1">
            <RadioButton
              name="inputStyle"
              value={'filled'}
              checked={layoutConfig.inputStyle === 'filled'}
              onChange={(e) => changeInputStyle(e)}
              inputId="filled_input"
            ></RadioButton>
            <label htmlFor="filled_input">Filled</label>
          </div>
        </div>

        <h5>Ripple Effect</h5>
        <InputSwitch
          checked={layoutConfig.ripple}
          onChange={(e) => changeRipple(e)}
        ></InputSwitch>

        <h5>Bootstrap</h5>
        <div className="grid">
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('bootstrap4-light-blue', 'light')}
            >
              <img
                src={img1}
                className="w-2rem h-2rem"
                alt="Bootstrap Light Blue"
              />
            </button>
          </div>
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('bootstrap4-light-purple', 'light')}
            >
              <img
                src={img2}
                className="w-2rem h-2rem"
                alt="Bootstrap Light Purple"
              />
            </button>
          </div>
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('bootstrap4-dark-blue', 'dark')}
            >
              <img
                src={img3}
                className="w-2rem h-2rem"
                alt="Bootstrap Dark Blue"
              />
            </button>
          </div>
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('bootstrap4-dark-purple', 'dark')}
            >
              <img
                src={img4}
                className="w-2rem h-2rem"
                alt="Bootstrap Dark Purple"
              />
            </button>
          </div>
        </div>

        <h5>Material Design</h5>
        <div className="grid">
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('md-light-indigo', 'light')}
            >
              <img
                src={img5}
                className="w-2rem h-2rem"
                alt="Material Light Indigo"
              />
            </button>
          </div>
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('md-light-deeppurple', 'light')}
            >
              <img
                src={img6}
                className="w-2rem h-2rem"
                alt="Material Light DeepPurple"
              />
            </button>
          </div>
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('md-dark-indigo', 'dark')}
            >
              <img
                src={img7}
                className="w-2rem h-2rem"
                alt="Material Dark Indigo"
              />
            </button>
          </div>
          <div className="col-3">
            <button
              className="p-link w-2rem h-2rem"
              onClick={() => changeTheme('md-dark-deeppurple', 'dark')}
            >
              <img
                src={img8}
                className="w-2rem h-2rem"
                alt="Material Dark DeepPurple"
              />
            </button>
          </div>
        </div>
      </Sidebar>
    </>
  );
};

export default AppConfig;
