import ProductoCard from "./productCard";
import '../css/productCard.css';

const GalleryAllProductos = ({ Products }) => {
    return (
        <div className="grid w-full">
            {Products?.map((item, index) => {
                return (
                    <div className="col-6 md:col-4 h-full">
                        <ProductoCard Product={item} key={'indexCard' + index}></ProductoCard>
                    </div>

                )
            })}
        </div>
    )
}
export default GalleryAllProductos;