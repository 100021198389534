import { useEffect, useRef, useState } from "react";
import Gallery from "../../../../components/gallery/Gallery.jsx";
import { useSelector } from "react-redux";
import ProductDescription from "./productDescription.jsx";
import { useLazyFetch } from "../../../../hooks/common/useFetchV2.js";
import { Galleria } from "primereact/galleria";

const ProductView = () => {
    const pageState = useSelector((state) => state.pageStateReducer);
    const productDetail = pageState.productDetail;
    const toast = useRef(null);
    const { getDataFetch } = useLazyFetch();
    const [imagenes, setImagenes] = useState([

    ])


    const getImg = async () => {
        let data = await getDataFetch('/public/v1/products/getProductGallery/' + productDetail.productId, 'GET', {});
        if (data.errorFetch) {
            toast.current.show({
                detail: 'No se logró consultar imagenes, intentelo más tarde',
                summary: 'Error',
                severity: 'error'
            })
        } else {
            setImagenes([
                {
                    imageUrl:productDetail.miniatureImageUrl
                },
                ...(data.data)]
            )
        }
    }
    useState(() => {
        getImg();
    }, [])
    const itemTemplate = (item) => {
        return <img src={item.imageUrl} style={{ width: '100%', objectFit:'cover', height:'40rem', backgroundColor:'gray'}} />
    }
    const thumbnailTemplate = (item) => {
        return <img src={item.imageUrl} style={{ width: '5rem', objectFit:'cover', height:'5rem' }} />
    }
    return (
        <>
            <div className="p-2 md:p-8">
                <div className="flex flex-wrap">
                    <div className="w-full md:w-6 pr-4">
                        <Galleria showIndicatorsOnItem style={{ width: '100%'}} value={imagenes}  item={itemTemplate} thumbnail={thumbnailTemplate} showThumbnails={true} circular showItemNavigators></Galleria>
                    </div>
                    <div className="w-full md:w-6 md:pl-7">
                        <ProductDescription detail={productDetail}></ProductDescription>
                    </div>
                </div>
                <div className="detail-desc mt-4">
                    <p className="title-desc">DETALLES DEL PRODUCTO:</p>
                    <p className="content-desc">
                        {productDetail.description}
                    </p>
                </div>
                {/* <div className="detail-desc">
                    <p className="title-desc">CARACTERÍSTICAS:</p>
                    <p className="content-desc">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer ac tortor sed purus egestas vehicula. In non lorem eu dui tempor cursus at non orci. Aenean eget purus vitae nisl viverra mattis. Suspendisse vitae enim ullamcorper, faucibus ipsum ac, sagittis augue. Cras pulvinar nulla at quam consequat, venenatis fringilla lorem lacinia. Pellentesque nec risus eros. Nam commodo posuere dolor eu facilisis.
                    </p>
                </div> */}
            </div>
        </>
    )
}
export default ProductView;