import React from 'react';

//HOOKS
import { useTranslation } from 'react-i18next';

const AppFooter = () => {
  const { t } = useTranslation();

  return (
    <div className="card-container">
      <div className="block font-bold text-center p-4 border-round mb-3 ">
        <p className="text-sm w-auto ">{t(`api.home-page-footer`)} </p>
      </div>
    </div>
  );
};

export default AppFooter;
