export const TYPE_PAGE_STATE = {
    SCROLL_VIEW:'SCROLL_VIEW',
    PRODUCT_DETAIL:'PRODUCT_DETAIL',
    SET_FILTERS:'SET_FILTERS',
    SET_AUXfILTERS:'SET_AUXfILTERS',
    SET_RECORDS:'SET_RECORDS',
    SET_PRICE_ITEM:'SET_PRICE_ITEM',
    SET_PRICE_KG:'SET_PRICE_KG',
    SET_SAMPLE_KG:'SET_SAMPLE_KG',
    SET_CATEGORY_ITEM:'SET_CATEGORY_ITEM',
    SET_CATEGORY_SELECT_ITEM:'SET_CATEGORY_SELECT_ITEM',
    SET_SUBCATEGORY_SELECT_ITEM:'SET_SUBCATEGORY_SELECT_ITEM',
    SET_SUBCATEGORY_ITEM:'SET_SUBCATEGORY_ITEM',
    

}